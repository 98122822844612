import _ from "lodash";
import env from "./env";
import {
  ErrorType as MxmErrorType,
  ErrorSubType as MxmErrorSubType,
} from "../lib/error-handling//error-handlers/mxm/mxmUtils";
import { Provider } from "../auth/core/useProviders";

export const environment = {
  envName: env(`ENV`) as string,
  isProduction: env(`ENV`) === "production",
  isTest: false,
};

export const errorReporting = {
  // TODO Eventually turn these off
  reportJwtExpiredErrors: true,
  reportJwtMissingErrors: true,
};

const allProviders: Provider[] = [`phone_number`, `email`, `apple`, `google`];
const enabledProviders: Provider[] = [
  `phone_number`,
  `email`,
  // TODO Re-enable this once we figure out why OAuth is broken
  `apple`,
  `google`,
];

export const auth = {
  allProviders,
  enabledProviders,
  unauthenticatedCredentials: {
    storageKeyNamespace: `auth`,
    storageKey: `unauthenticated_credentials.v2`,
  },
  forceLogOut: {
    reasons: {
      USER_MISSING: { enabled: false },
      IDENTITY_MISSING: { enabled: false },
      CREDENTIAL_MISMATCH: { enabled: false },
      USER_PENDING_DELETION: { enabled: false },
      USER_DELETED: { enabled: false },
      USER_LOCKED: { enabled: false },
      USER_FRAUDULENT: { enabled: false },
    },
  },
};

export const parking = {
  recentParkableTransactionsRefetchInterval: 30 * 1000, // 30s
};

export const mxm = {
  reportErrorTypes: {
    [MxmErrorSubType.INVALID_CARD_NUMBER]: true,
    [MxmErrorSubType.INVALID_EXPIRY]: true,
    [MxmErrorSubType.INVALID_CVV]: true,
    [MxmErrorSubType.INVALID_AVS_ZIP]: true,
    [MxmErrorType.VALIDATION]: true,
    [MxmErrorType.UNKOWN]: true,
  },
};

let _timeDiscrepancy: number | null = null;

export function setTimeDiscrepancy(timeDiscrepancy: number) {
  _timeDiscrepancy = timeDiscrepancy;
}

export function getTimeDiscrepancy() {
  const timeDiscrepancy = _.isNumber(_timeDiscrepancy) ? (_timeDiscrepancy as number) : 0;

  return timeDiscrepancy;
}

export function getGraphqlEndpoint(envName: string = environment.envName) {
  switch (envName) {
    case "local":
      // Localhost / Laradock - Optionally through ngrok
      // return "http://localhost:8081/v1/graphql";
      // TODO Probably remove this from production builds if possible, since it has my zsl.io url in it
      return "https://local.graphql.meterez.com/v1/graphql";
    case "development":
      return "https://gql-development.meterez.com/v1/graphql";
    case "preview":
      return "https://gql-production.meterez.com/v1/graphql";
    case "production":
      return "https://gql-production.meterez.com/v1/graphql";
      return "...";
  }
}

export function getMxmEndpoint(envName: string = environment.envName) {
  switch (envName) {
    case "local":
    case "development":
    case "demo":
      return "https://sandbox.api.mxmerchant.com/checkout/v3";
    case "preview":
    case "production":
      return "https://api.mxmerchant.com/checkout/v3";
  }
}

export function getAuthEndpoint(envName: string = environment.envName) {
  switch (envName) {
    case "local":
    case "development":
    case "demo":
      return "https://local.identity.meterez.com";
    case "preview":
    case "production":
      return "https://identity.meterez.com";
  }
}